import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";
import { validate_date } from "../utils/validation";

const LOCAL_STORAGE_KEY = process.env.REACT_APP_LS_PREFIX + "_notif_rc";

const gen_tracking_url = (carrier, tracking) => {
  //convert variations of FEDEX to FEDEX (examples: Fed Ex, FedEx, FedEx Express, etc.)
  if (carrier === undefined || carrier === null) {
    carrier = "GSO";
  }
  if (carrier.match(/fed.*ex/i)) {
    carrier = "FEDEX";
  } else if (carrier.match(/ups/i)) {
    carrier = "UPS";
  }

  switch (carrier) {
    case "UPS":
      return `https://www.ups.com/track?loc=en_US&tracknum=${tracking}`;
    case "FEDEX":
      return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${tracking}`;
    case "GSO":
      return `https://www.gso.com/Tracking`;
    default:
      return "#";
  }
};

const OrderStatusTimeline = ({ timeline }) => {
  const { steps } = timeline;

  return (
    <div className='w-full max-w-4xl mx-auto p-4'>
      <div className='hidden md:block'>
        <div className='relative'>
          {/* Circles and Labels */}
          <div className='flex items-start justify-between'>
            {steps.map((step, index) => (
              <div
                key={index}
                className='flex flex-col items-center w-1/6 first:pl-4 last:pr-4'
              >
                <div className='w-8 h-8 mb-2 relative'>
                  {/* Horizontal line segment */}
                  {index < steps.length - 2 && (
                    <div className='absolute left-1/2 top-1/2 w-[calc(450%+5rem)] h-0.5 bg-black -translate-y-1/2'></div>
                  )}

                  {index === steps.length - 1 && (
                    <div className='absolute left-1/2 top-1/2 w-[calc(450%+5rem)] h-0.5 bg-black -translate-y-1/2 -translate-x-60'></div>
                  )}

                  {/* Circle */}
                  <div
                    className={`w-8 h-8 rounded-full border-2 relative z-10 ${
                      step.active
                        ? "border-[#0057B8] bg-[#0057B8]"
                        : index < steps.findIndex(s => s.active)
                        ? "border-black bg-black"
                        : "border-black bg-white"
                    }`}
                  ></div>
                </div>
                <div
                  className={`text-center ${
                    step.active
                      ? "font-bold text-sm text-[#0057B8]"
                      : "text-xs text-black"
                  } w-full px-1`}
                >
                  {step.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Vertical view */}
      <div className='md:hidden'>
        <div className='relative pl-4'>
          {steps.map((step, index) => (
            <div key={index} className='flex items-center mb-8 last:mb-0'>
              <div className='relative'>
                {/* Vertical line segment */}
                {index < steps.length - 1 && (
                  <div className='absolute left-1/2 top-4 h-[calc(200%+1rem)] w-0.5 bg-black -translate-x-1/2'></div>
                )}
                {/* Circle */}
                <div
                  className={`w-8 h-8 rounded-full border-2 relative z-10 ${
                    step.active
                      ? "border-[#0057B8] bg-[#0057B8]"
                      : index < steps.findIndex(s => s.active)
                      ? "border-black bg-black"
                      : "border-black bg-white"
                  }`}
                ></div>
              </div>
              {/* Label */}
              <div
                className={`ml-4 ${
                  step.active
                    ? "font-bold text-sm text-[#0057B8]"
                    : "text-xs text-black"
                }`}
              >
                {step.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TimelinesWrapper = ({ handleDownloadPOD, extMsg }) => {
  const { wos_data = null, timelinePkg, order_type, shipments = [] } = extMsg;

  console.log({ a: timelinePkg[order_type] });

  let title =
    extMsg.key === "wos"
      ? `Work Order W${extMsg.wos_id}`
      : `Workunit V${extMsg.v_id}`;

  const show_shipment_lines = shipments.length > 0;

  return (
    <div className='flex flex-col items-center pt-4'>
      <div className='flex flex-col mb-8 w-full max-w-[80vw]'>
        <div className='border-gray-200 border-2 p-8 rounded-lg shadow-md'>
          <span className='mt-2 font-semibold text-sm text-gray-600'>
            {title}
          </span>
          <OrderStatusTimeline timeline={timelinePkg[order_type]} />
          {wos_data && wos_data.lines && wos_data.lines.length > 0 && (
            <div className='mt-6'>
              <h3 className='text-gray-600 font-semibold mb-2'>
                Contents of Order:
              </h3>
              <ol className='list-decimal pl-4 mb-10'>
                {wos_data.lines.map((line, lineIndex) => (
                  <li key={lineIndex} className='text-sm mb-3'>
                    <span className='text-orange-700 mr-2'>
                      QTY {line.qty_ordered} {line.uom}
                    </span>
                    {line.product_desc || line.item} {line.wo_type_str}
                  </li>
                ))}
              </ol>
            </div>
          )}
          {shipments.length > 0 && (
            <div className='mt-6'>
              <h3 className='text-gray-600 font-semibold mb-2'>Shipments:</h3>
              <ul className=''>
                {shipments.map((s, si) => {
                  let url = gen_tracking_url(s.c, s.tn);
                  return (
                    <li key={si} className='text-sm mb-3'>
                      {s.c} {s.tn} <br />
                      <a
                        href={url}
                        className='text-blue-600'
                        target={s.tn.replace(/ /g, "")}
                      >
                        {url}
                      </a>
                      {show_shipment_lines && (
                        <ol className='list-decimal ml-4 mt-2'>
                          {s.lines.map((line, lineIndex) => (
                            <li key={lineIndex} className='text-sm mb-3'>
                              <span className='text-orange-700 mr-2'>
                                QTY {line.qty || line.qty_ordered} {line.uom}
                              </span>
                              {line.product_desc || line.item}
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <p className='mt-10'>
            Note: The order information showing here is an approximation of your
            order status and order contents and may not include all details
            about your order.
          </p>

          {/* {extMsg.pod_sub_id && (
            <div className='pt-8'>
              <hr />
              <button
                className='bg-blue-500 text-white mt-4 py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300'
                onClick={() => {
                  handleDownloadPOD(extMsg.pod_sub_id, extMsg.account);
                }}
              >
                Download Delivery Receipt (PDF)
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

function NotifLanding() {
  //obtain referral_code from the route parameter
  const { url_hash } = useParams();
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [message, setMessage] = useState("");
  const [isDoingLandingChecks, setIsDoingLandingChecks] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hashCodeValid, setHashCodeValid] = useState(true);
  // const [timelines, setTimelines] = useState([]);
  const [extMsg, setExtMsg] = useState(null);
  const [urlToAOB, setUrlToAOB] = useState(null);

  //upon loading the page, we have to first do an api call to the backend to check if the referal code is valid and not expired, then we can show the form to the user
  const hashCodeChecked = useRef(false);

  //this is only necessary during Phase 1 - so that subsequent refreshing on this route does not ask for MM DD again
  //so long as the referral code has not expired has and has been validated, and MMDD was already verified
  // const [hasAccessToken, setHasAccessToken] = useState(false);

  const [didJustVerifyMMDD, setDidJustVerifyMMDD] = useState(false);

  // const navigate = useNavigate();
  // const setAccessToken = jwt => {
  //   localStorage.setItem(
  //     process.env.REACT_LC_PREFIX,
  //     JSON.stringify({
  //       a: jwt,
  //       i: new Date().getTime(),
  //     })
  //   );
  //   setHasAccessToken(true);
  // };

  useEffect(() => {
    if (hashCodeChecked.current) return;
    // let notif_rc_local = localStorage.getItem(LOCAL_STORAGE_KEY);
    checkhashCode();
  });

  const clearSession = msg => {
    //force clear session and take user back to MMDD verification
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    setMessage(msg);

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const storeSession = sh => {
    console.log({ msg: "storing session in ", LOCAL_STORAGE_KEY, sh });
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sh));
  };

  const getSession = () => {
    let sh = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (sh === null) return null;
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
  };

  const checkhashCode = () => {
    // console.log("checking referral code");
    hashCodeChecked.current = true;
    let sh = getSession();
    let payload = { h: url_hash };
    if (sh) {
      payload.sh = sh;
    }
    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/notif`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        if (data.success === false) {
          throw data.error;
        }
        if (data.success === true) {
          setIsDoingLandingChecks(false);
          setHashCodeValid(true);

          if (data.extMsg) {
            setDidJustVerifyMMDD(true);
            setExtMsg(data.extMsg);
          }

          if (data.aob_prefilled_url) {
            setUrlToAOB(data.aob_prefilled_url);
          }
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setHashCodeValid(false);
        setIsDoingLandingChecks(false);
        //clear local storage referral code so that next attempt requires MMDD again
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("");
    if (!validate_date(month, day)) {
      // set message to "Invalid date"
      setMessage("Invalid date");
      setIsSubmitting(false);
      return;
    }

    //convert month and day to mmdd format
    const mmdd = month.padStart(2, "0") + day.padStart(2, "0");

    /* 
      Make a cross domain fetch post to the bakcend (server is stored in env var REACT_APP_API_SERVER) at path '/api/pp2/login'
      with body { mmdd, referral_code }. 
      If error, set message to error message from server, if success, call setAccessToken() passing in access token from server
    */
    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/notif`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ mmdd, h: url_hash }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage(data.error);
        } else {
          setMessage("");
          // setAccessToken(data.access_token);
          setDidJustVerifyMMDD(true);
          setExtMsg(data.extMsg);
          if (data.sh) {
            storeSession(data.sh);
          }

          if (data.aob_prefilled_url) {
            setUrlToAOB(data.aob_prefilled_url);
          }

          // remove local storage referral code so that refresh doesn't find the valid referral code
          // localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
          // setMessage("...signing you in...");
        }
        setIsSubmitting(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsSubmitting(false);
      });
  };

  const handleDownloadPOD = (pod_sub_id, account) => {
    //this should make a fetch to "/api/pp2/pod/:pod_sub_id" with the pod_sub_id,
    //but it should also pass the session token as param sh
    //if the session token is not valid, it should return an error
    //if the pod_sub_id is not valid, it should return an error
    let payload = { account };
    let sh = getSession();
    if (sh) {
      payload.sh = sh;
    } else {
      return clearSession(
        "Your session has expired. Please re-enter MMDD to continue."
      );
    }

    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/pod/${pod_sub_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          return clearSession(
            "Your session has expired. Please re-enter MMDD to continue."
          );
        }
        if (data.success === false) {
          throw data.error;
        }
        if (data.success === true) {
          //download the file
          window.open(data.url, "_blank");
        }
      })
      .catch(error => {
        console.error(error);
        return clearSession(
          "Your session has expired. Please re-enter MMDD to continue."
        );
      });
  };

  //for demo and qa only
  // const handleQAReset = () => {
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX);
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
  //   setMonth("");
  //   setDay("");
  //   setMessage("");
  //   setIsSubmitting(false);
  //   setHasAccessToken(false);
  //   hashCodeChecked.current = false;
  // };

  //end demo and qa stuff

  if (isDoingLandingChecks) {
    return (
      <div className='flex justify-center items-center'>
        <div className='p-8 rounded-lg w-80'>
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  // if (!hashCodeChecked) {
  //   // checkhashCode();
  //   return (
  //     <div className="flex justify-center items-center ">
  //       <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
  //         <p className="mt-2 text-center text-sm text-gray-600">
  //           ...checking referral code...
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (!hashCodeValid) {
    return (
      <div className='flex justify-center'>
        <div className='bg-gray-200 p-8 rounded-lg shadow-md w-80'>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Link is invalid or has expired
            <br /> ErrCode N1.
          </p>
        </div>
      </div>
    );
  }

  if (didJustVerifyMMDD) {
    if (false && urlToAOB) {
      return (
        <div>
          <div className='bg-orange-400 p-8 rounded-lg shadow-md w-100'>
            <p className='mt-2 text-sm text-black'>
              <strong>Please complete AOB: </strong>
              <a href={urlToAOB} target='_blank' rel='noreferrer'>
                [Click here to fill it out]
              </a>
              {/* Please call SuperCare or reach us via chat at www.supercarehealth.com to unsubscribe. */}
              {/* <br /> */}
              {/* [Onboarding Milestone 20 reached - User verified MMDD] */}
            </p>
            <p className='mt-8'>
              [Need Hermes team to provide me with UI cleanup/verbag here]
            </p>
          </div>

          <TimelinesWrapper extMsg={extMsg} />
        </div>
      );
    }

    return (
      <div>
        <TimelinesWrapper
          handleDownloadPOD={handleDownloadPOD}
          extMsg={extMsg}
        />
        {message && (
          <div className='bg-red-400 p-8 rounded-lg shadow-md w-100'>
            <p className='mt-2 text-sm text-black'>{message}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='flex justify-center'>
      <div className='bg-gray-200 p-8 rounded-lg shadow-md w-80'>
        {/* <p className="mt-2 text-center text-sm text-gray-600">
          Your referral code is: {referral_code}
        </p> */}
        <h2 className='text-1xl font-bold mb-4'>
          Please enter the 2 digit month and 2 digit day of the patient's
          birthday:
        </h2>
        <div className='flex mb-4'>
          <div className='w-1/2'>
            <label
              htmlFor='month'
              className='block text-sm font-medium text-gray-600 mb-1'
            >
              Month (MM)
            </label>
            <input
              autoComplete='off'
              type='text'
              id='month'
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
              placeholder=''
              value={month}
              maxLength={2}
              onChange={e => setMonth(e.target.value)}
            />
          </div>
          <div className='mx-2 self-center'>/</div>
          <div className='w-1/2'>
            <label
              htmlFor='day'
              className='block text-sm font-medium text-gray-600 mb-1'
            >
              Day (DD)
            </label>
            <input
              autoComplete='off'
              type='text'
              id='day'
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
              placeholder=''
              value={day}
              maxLength={2}
              onChange={e => setDay(e.target.value)}
            />
          </div>
        </div>
        {isSubmitting ? (
          <button className='w-full bg-gray-200 text-white py-2 rounded-md hover:bg-gray-100 transition duration-300'>
            ...attempting verification...
          </button>
        ) : (
          <button
            className='w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300'
            onClick={handleSubmit}
          >
            Submit
          </button>
        )}

        {message && <p className='mt-4 text-center'>{message}</p>}

        {/* {message && <p className='mt-4 text-center'>{message}</p>}
        <p className='font-thin text-xs mt-4 pt-8'>
          Disclaimer: By verifying the month and day of birth you are consenting
          for SuperCareHealth communicating with you via SMS/email.
        </p>
        <p className='text-xs mt-4 pt-8 font-bold'>
          FOR QA: Disclaimer above should be removed for this flow???
        </p> */}
      </div>

      {/* -- for qa and demo only -- */}
      {/* <div className="bg-yellow-100 p-8 rounded-lg shadow-md mt-20 w-80">
        <p className="mt-2 mb-12 text-center text-sm text-gray-600">
          This section is displayed ONLY for DEMO/QA PURPOSES so you can retart
          the flow from the beginning.
        </p>

        <button
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
          onClick={handleQAReset}
        >
          Clear Session
        </button>
      </div> */}
    </div>
  );
}

export default NotifLanding;
